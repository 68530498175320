import PublicRoutes from "./routes/PublicRoutes";
import "../src/assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

function App() {
  return (
    <>
      <PublicRoutes />
    </>
  );
}

export default App;
